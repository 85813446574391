<template>
  <div>
    <Navbar class="nav-z-idx"/>
    <div class="container-v3">
      <div class="center-content-v3">
        <div class="form-login-v3">
          <p class="title-form-v3">Reset Password</p>
          <p class="sub-title-form-v3">Back to <span><router-link tag="a" to="/login" class="blue-text-v3" href="">Sign In</router-link></span></p>
          <!-- <form action="" class="form-input" @submit.prevent="sendResetPassword">
            <input
              type="text"
              v-model="email"
              class="form-control input-box-v3"
              placeholder="Email"
              @blur="$v.email.$touch()">
            <template v-if="$v.email.$error">
              <span v-if="!$v.email.email" id="email-error-fp" class="required-color error-message-v3">Please enter a valid email (e.g., example@mail.com).</span>
              <span v-if="!$v.email.required" id="email-empty-fp" class="required-color error-message-v3">Email cannot be empty.</span>
              <span v-if="!this.stateEmail" id="email-already-registered" class="required-color error-message-v3">This email is not registered. Please try another email or create a new account with this email.</span>
            </template>
            <div class="mt-3">
              <button class="forgot-password-btn-v3" :disabled="$v.$invalid">
                <i v-show="loading" class="bx bx-loader font-size-16 bx-tada"></i>
                Send Reset Link
              </button>
            </div>
          </form> -->
          
          <b-form action="" @submit.prevent="sendResetPassword">
            <div class="form-input">
              <b-form-group :invalid-feedback="invalidEmail" :state="stateEmail">
                <b-form-input
                  v-model.trim="$v.email.$model"
                  type="email"
                  class="form-control input-box-v3"
                  :class="{'is-invalid': validationStatus($v.email)}"
                  placeholder="Email"></b-form-input>
                  <div class="invalid-feedback">
                    <span v-if="!$v.email.email" id="email-error-fp" class="required-color error-message-v3">Please enter a valid email (e.g., example@mail.com).</span>
                    <span v-if="!$v.email.required" id="email-empty-fp" class="required-color error-message-v3">Email cannot be empty.</span>
                    <!-- <span id="email-already-registered" class="required-color error-message-v3">This email is already registered. Sign in with this email or reset your password.</span> -->
                  </div>
              </b-form-group>
            </div>
            <button class="forgot-password-btn-v3" :disabled="$v.$invalid">
              <i v-show="loading" class="bx bx-loader font-size-16 bx-tada"></i>
              Send Reset Link
            </button>
          </b-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "../../../components/nav-login-registerV3.vue";
import { required, email } from 'vuelidate/lib/validators'
import { authMethods } from "@/state/helpers";

export default {
  name: 'ForgotPassword',
  components: {
    Navbar
  },
  data() {
    return {
      email: '',
      loading: false,
      authError: null,
      isAuthError: false,
      tryingToSendEmail: false,
      invalidEmail : "",
      stateEmail : true,
      feedback: ''
    }
  },
  validations: {
    email: {
      required,
      email,
      isUnique (value) {
        if (value === '') return true
        var email_regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        return new Promise((resolve) => {
          setTimeout(() => {
            resolve(email_regex.test(value))
          }, 350 + Math.random()*300)
        })
      }
    }
  },
  methods: {
    ...authMethods,
    validationStatus(validation) {
      return typeof validation != undefined ? validation.$error: false
    },
    sendResetPassword() {
      this.$v.$touch()
      this.loading = true;
      this.tryingToSendEmail = true;
      // Reset the authError if it existed.
      this.authError = null;
      this.isAuthError = false;
      return (
        this.forgotPassword({
          email: this.email
        })
          // eslint-disable-next-line no-unused-vars
          .then(token => {
            this.tryingToSendEmail = false;
            this.isAuthError = true;
            this.authError = token;
            this.stateEmail = true;
            this.loading = false;
            this.$router.push(`/reset-message/${this.email}`)
          })
          .catch(error => {
            this.loading = false;
            this.tryingToSendEmail = false;
            this.invalidEmail = typeof error == 'object' ? error.data.display_message : "";
            this.stateEmail = false;
            this.isAuthError = false;
          })
      );
    }
  }
}
</script>

<style>

</style>
